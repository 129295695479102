import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import {motion} from 'framer-motion'
import {zenPayrollData} from 'v1/content/home-page'
import classes from './styles.module.css'

const ZenPayroll: React.FC = () => {
  const [activeButton, setActiveButton] = useState(0)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (isMobile) return

    const interval = setInterval(() => {
      setActiveButton(prev => (prev + 1) % 5)
    }, 8000)
    return () => clearInterval(interval)
  }, [isMobile])

  return (
    <section className={classes.zenPayrollBg}>
      <div className={classes.zenPayrollContainer}>
        <div className={classes.topSection}>
          <h6 className={clsx('h6-v1', classes.topTitle)}>
            {zenPayrollData?.headingData?.topTitle}
          </h6>
          <h2 className="h2-v1">{zenPayrollData?.headingData?.title}</h2>
          <p className="paragraph1-v1">
            {zenPayrollData?.headingData?.subTitleText1} <br />{' '}
            {zenPayrollData?.headingData?.subTitleText2}
          </p>
        </div>

        <div className={classes.centeredDetailContainer}>
          <div className={classes.detailsContainer}>
            <h4 className={clsx('h4-v1', classes.detailsTitle)}>
              {zenPayrollData?.tabs?.[activeButton]?.title}
            </h4>
            <h6 className="h6-v1">{zenPayrollData?.tabs?.[activeButton]?.subTitle}</h6>
            <p className="paragraph2-v1">{zenPayrollData?.tabs?.[activeButton]?.paragraph}</p>
          </div>
          <motion.div
            key={activeButton}
            className={classes.imageContainer}
            transition={{duration: 0.5}}
          >
            <Image
              className={classes.image}
              src={zenPayrollData?.tabs?.[activeButton]?.image}
              alt={`Image ${activeButton}`}
            />
          </motion.div>
        </div>

        <ButtonSection handleIndexChange={setActiveButton} activeButton={activeButton} />
      </div>
    </section>
  )
}

const ButtonSection = ({
  activeButton,
  handleIndexChange,
}: {
  activeButton: number
  handleIndexChange: (index: number) => void
}) => {
  return (
    <div className={classes.buttonContainer}>
      {zenPayrollData?.tabs?.map((data, index) => (
        <motion.button
          key={data?.key}
          className={`${classes.button} ${activeButton === index ? classes.active : ''}`}
          onClick={() => handleIndexChange(index)}
        >
          <span className={classes.fill} />
          {data?.title}
        </motion.button>
      ))}
    </div>
  )
}

export default ZenPayroll
