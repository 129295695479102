export const lines = [
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="52"
      viewBox="0 0 365 52"
      fill="none"
      key="line1"
    >
      <path
        d="M.333 3a2.667 2.667 0 1 0 5.334 0A2.667 2.667 0 0 0 .333 3m362.313 48.354a.5.5 0 0 0 .708 0l3.182-3.182a.5.5 0 0 0-.708-.707L363 50.293l-2.828-2.828a.501.501 0 0 0-.708.707zM2.5 3v4h1V3zM23 27.5h320v-1H23zM362.5 47v4h1v-4zM343 27.5c10.77 0 19.5 8.73 19.5 19.5h1c0-11.322-9.178-20.5-20.5-20.5zM2.5 7c0 11.322 9.178 20.5 20.5 20.5v-1C12.23 26.5 3.5 17.77 3.5 7z"
        fill="#3157DC"
      />
    </svg>
  </>,
  <>
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="52" viewBox="0 0 8 52" fill="none" key="line2">
      <path
        d="M1.333 3a2.667 2.667 0 1 0 5.334 0 2.667 2.667 0 0 0-5.334 0m2.313 48.354a.5.5 0 0 0 .708 0l3.182-3.182a.5.5 0 1 0-.708-.707L4 50.293l-2.828-2.828a.5.5 0 1 0-.708.707zM3.5 3v48h1V3z"
        fill="#3157DC"
      />
    </svg>
  </>,
  <>
    <svg
      width="367"
      height="52"
      viewBox="0 0 367 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key="line2"
    >
      <path
        d="M361.333 3C361.333 4.47276 362.527 5.66667 364 5.66667C365.473 5.66667 366.667 4.47276 366.667 3C366.667 1.52724 365.473 0.333333 364 0.333333C362.527 0.333333 361.333 1.52724 361.333 3ZM3.64645 51.3536C3.84171 51.5488 4.15829 51.5488 4.35355 51.3536L7.53553 48.1716C7.7308 47.9763 7.7308 47.6597 7.53553 47.4645C7.34027 47.2692 7.02369 47.2692 6.82843 47.4645L4 50.2929L1.17157 47.4645C0.976311 47.2692 0.659728 47.2692 0.464466 47.4645C0.269204 47.6597 0.269204 47.9763 0.464466 48.1716L3.64645 51.3536ZM363.5 3V7H364.5V3H363.5ZM344 26.5H24V27.5H344V26.5ZM3.5 47V51H4.5V47H3.5ZM24 26.5C12.6782 26.5 3.5 35.6782 3.5 47H4.5C4.5 36.2304 13.2305 27.5 24 27.5V26.5ZM363.5 7C363.5 17.7696 354.77 26.5 344 26.5V27.5C355.322 27.5 364.5 18.3218 364.5 7H363.5Z"
        fill="#3157DC"
      />
    </svg>
  </>,
]

export const connectorIcon = (
  <svg width="24" height="6" viewBox="0 0 24 6" fill="none" xmlns="http://www.w3.org/2000/svg" key="connector">
    <path
      d="M0.333333 2.9999C0.333333 1.52714 1.52724 0.333233 3 0.333233C4.47276 0.333233 5.66667 1.52714 5.66667 2.9999C5.66667 4.47266 4.47276 5.66657 3 5.66657C1.52724 5.66657 0.333333 4.47266 0.333333 2.9999ZM18.3333 3C18.3333 1.52724 19.5272 0.333333 21 0.333333C22.4728 0.333333 23.6667 1.52724 23.6667 3C23.6667 4.47276 22.4728 5.66667 21 5.66667C19.5272 5.66667 18.3333 4.47276 18.3333 3ZM3 2.4999H12V3.4999H3V2.4999ZM12 2.5H21V3.5H12V2.5ZM12.5 2.99995C12.5 2.72384 12.2762 2.5 12 2.5V3.5C11.7239 3.5 11.5 3.27612 11.5 2.99995H12.5ZM12 2.4999C12.2846 2.4999 12.5 2.73233 12.5 2.99995H11.5C11.5 3.26752 11.7154 3.4999 12 3.4999V2.4999Z"
      fill="#3157DC"
    />
  </svg>
)

export const singleCardTopLines = [
  <svg width="428" height="42" viewBox="0 0 428 42" fill="none" xmlns="http://www.w3.org/2000/svg" key="line21">
    <path
      d="M3.64645 41.3536C3.84171 41.5488 4.15829 41.5488 4.35355 41.3536L7.53553 38.1716C7.7308 37.9763 7.7308 37.6597 7.53553 37.4645C7.34027 37.2692 7.02369 37.2692 6.82843 37.4645L4 40.2929L1.17157 37.4645C0.976311 37.2692 0.659728 37.2692 0.464466 37.4645C0.269204 37.6597 0.269204 37.9763 0.464466 38.1716L3.64645 41.3536ZM426.5 0V0.5H427.5V0H426.5ZM407 20H24V21H407V20ZM3.5 40.5V41H4.5V40.5H3.5ZM24 20C12.6782 20 3.5 29.1782 3.5 40.5H4.5C4.5 29.7304 13.2304 21 24 21V20ZM426.5 0.5C426.5 11.2696 417.77 20 407 20V21C418.322 21 427.5 11.8218 427.5 0.5H426.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="8" height="45" viewBox="0 0 8 45" fill="none" xmlns="http://www.w3.org/2000/svg" key="line22">
    <path
      d="M1.33343 3C1.33343 4.47276 2.52734 5.66667 4.0001 5.66667C5.47286 5.66667 6.66677 4.47276 6.66677 3C6.66677 1.52724 5.47286 0.333333 4.0001 0.333333C2.52734 0.333333 1.33343 1.52724 1.33343 3ZM3.64645 44.3536C3.84171 44.5488 4.15829 44.5488 4.35355 44.3536L7.53553 41.1716C7.7308 40.9763 7.7308 40.6597 7.53553 40.4645C7.34027 40.2692 7.02369 40.2692 6.82843 40.4645L4 43.2929L1.17157 40.4645C0.976311 40.2692 0.659728 40.2692 0.464466 40.4645C0.269204 40.6597 0.269204 40.9763 0.464466 41.1716L3.64645 44.3536ZM3.5001 3V23.5H4.5001V3H3.5001ZM3.5 23.5V44H4.5V23.5H3.5ZM4.00005 23C3.72388 23 3.5 23.2239 3.5 23.5H4.5C4.5 23.7762 4.27616 24 4.00005 24V23ZM3.5001 23.5C3.5001 23.2251 3.72267 23 4.00005 23V24C4.27749 24 4.5001 23.7748 4.5001 23.5H3.5001Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="426" height="42" viewBox="0 0 426 42" fill="none" xmlns="http://www.w3.org/2000/svg" key="line23">
    <path
      d="M421.646 41.3536C421.842 41.5488 422.158 41.5488 422.354 41.3536L425.536 38.1716C425.731 37.9763 425.731 37.6597 425.536 37.4645C425.34 37.2692 425.024 37.2692 424.828 37.4645L422 40.2929L419.172 37.4645C418.976 37.2692 418.66 37.2692 418.464 37.4645C418.269 37.6597 418.269 37.9763 418.464 38.1716L421.646 41.3536ZM0.5 0V0.5H1.5V0H0.5ZM21 21H402V20H21V21ZM421.5 40.5V41H422.5V40.5H421.5ZM402 21C412.77 21 421.5 29.7304 421.5 40.5H422.5C422.5 29.1782 413.322 20 402 20V21ZM0.5 0.5C0.5 11.8218 9.67816 21 21 21V20C10.2304 20 1.5 11.2696 1.5 0.5H0.5Z"
      fill="#3157DC"
    />
  </svg>,
]

export const singleCardBottomLines = [
  <svg width="315" height="50" viewBox="0 0 315 50" fill="none" xmlns="http://www.w3.org/2000/svg" key="line31">
    <path
      d="M314.354 46.3536C314.549 46.1583 314.549 45.8417 314.354 45.6464L311.172 42.4645C310.976 42.2692 310.66 42.2692 310.464 42.4645C310.269 42.6597 310.269 42.9763 310.464 43.1716L313.293 46L310.464 48.8284C310.269 49.0237 310.269 49.3403 310.464 49.5355C310.66 49.7308 310.976 49.7308 311.172 49.5355L314.354 46.3536ZM5.66669 3C5.66669 1.52724 4.47278 0.333332 3 0.333332C1.52725 0.333332 0.333344 1.52724 0.333344 3C0.333344 4.47276 1.52725 5.66666 3 5.66666C4.47278 5.66666 5.66669 4.47276 5.66669 3ZM314 45.5H23V46.5H314V45.5ZM3.5 26V3H2.5V26H3.5ZM23 45.5C12.2305 45.5 3.5 36.7696 3.5 26H2.5C2.5 37.3218 11.6782 46.5 23 46.5V45.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="110" height="50" viewBox="0 0 110 50" fill="none" xmlns="http://www.w3.org/2000/svg" key="line32">
    <path
      d="M109.354 46.3536C109.549 46.1583 109.549 45.8417 109.354 45.6464L106.172 42.4645C105.976 42.2692 105.66 42.2692 105.464 42.4645C105.269 42.6597 105.269 42.9763 105.464 43.1716L108.293 46L105.464 48.8284C105.269 49.0237 105.269 49.3403 105.464 49.5355C105.66 49.7308 105.976 49.7308 106.172 49.5355L109.354 46.3536ZM5.66667 3C5.66667 1.52724 4.47276 0.333332 3 0.333332C1.52724 0.333332 0.333336 1.52724 0.333336 3C0.333336 4.47276 1.52724 5.66666 3 5.66666C4.47276 5.66666 5.66667 4.47276 5.66667 3ZM109 45.5H23V46.5H109V45.5ZM3.5 26V3H2.5V26H3.5ZM23 45.5C12.2304 45.5 3.5 36.7696 3.5 26H2.5C2.5 37.3218 11.6782 46.5 23 46.5V45.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="8" height="27" viewBox="0 0 8 27" fill="none" xmlns="http://www.w3.org/2000/svg" key="line33">
    <path
      d="M1.33333 3C1.33333 4.47276 2.52724 5.66667 4 5.66667C5.47276 5.66667 6.66667 4.47276 6.66667 3C6.66667 1.52724 5.47276 0.333333 4 0.333333C2.52724 0.333333 1.33333 1.52724 1.33333 3ZM3.64645 26.3536C3.84171 26.5488 4.15829 26.5488 4.35355 26.3536L7.53553 23.1716C7.7308 22.9763 7.7308 22.6597 7.53553 22.4645C7.34027 22.2692 7.02369 22.2692 6.82843 22.4645L4 25.2929L1.17157 22.4645C0.976311 22.2692 0.659728 22.2692 0.464466 22.4645C0.269204 22.6597 0.269204 22.9763 0.464466 23.1716L3.64645 26.3536ZM3.5 3V26H4.5V3H3.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="110" height="50" viewBox="0 0 110 50" fill="none" xmlns="http://www.w3.org/2000/svg" key="line34">
    <path
      d="M0.646447 46.3536C0.451184 46.1583 0.451184 45.8417 0.646447 45.6464L3.82843 42.4645C4.02369 42.2692 4.34027 42.2692 4.53553 42.4645C4.7308 42.6597 4.7308 42.9763 4.53553 43.1716L1.70711 46L4.53553 48.8284C4.7308 49.0237 4.7308 49.3403 4.53553 49.5355C4.34027 49.7308 4.02369 49.7308 3.82843 49.5355L0.646447 46.3536ZM104.333 3C104.333 1.52724 105.527 0.333331 107 0.333332C108.473 0.333332 109.667 1.52724 109.667 3C109.667 4.47276 108.473 5.66666 107 5.66666C105.527 5.66666 104.333 4.47276 104.333 3ZM1 45.5L87 45.5L87 46.5L1 46.5L1 45.5ZM106.5 26L106.5 3L107.5 3L107.5 26L106.5 26ZM87 45.5C97.7695 45.5 106.5 36.7696 106.5 26L107.5 26C107.5 37.3219 98.3218 46.5 87 46.5L87 45.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="315" height="50" viewBox="0 0 315 50" fill="none" xmlns="http://www.w3.org/2000/svg" key="line35">
    <path
      d="M0.646447 46.3536C0.451184 46.1583 0.451184 45.8417 0.646447 45.6464L3.82843 42.4645C4.02369 42.2692 4.34027 42.2692 4.53553 42.4645C4.7308 42.6597 4.7308 42.9763 4.53553 43.1716L1.70711 46L4.53553 48.8284C4.7308 49.0237 4.7308 49.3403 4.53553 49.5355C4.34027 49.7308 4.02369 49.7308 3.82843 49.5355L0.646447 46.3536ZM309.333 3C309.333 1.52724 310.527 0.333332 312 0.333332C313.473 0.333332 314.667 1.52724 314.667 3C314.667 4.47276 313.473 5.66666 312 5.66666C310.527 5.66666 309.333 4.47276 309.333 3ZM1 45.5H292V46.5H1V45.5ZM311.5 26V3H312.5V26H311.5ZM292 45.5C302.77 45.5 311.5 36.7696 311.5 26H312.5C312.5 37.3218 303.322 46.5 292 46.5V45.5Z"
      fill="#3157DC"
    />
  </svg>,
]

export const mobileLeftLines = [
  <svg width="18" height="81" viewBox="0 0 18 81" fill="none" xmlns="http://www.w3.org/2000/svg" key="lineml1">
    <path
      d="M17.3536 77.3536C17.5488 77.1583 17.5488 76.8417 17.3536 76.6464L14.1716 73.4645C13.9763 73.2692 13.6597 73.2692 13.4645 73.4645C13.2692 73.6597 13.2692 73.9763 13.4645 74.1716L16.2929 77L13.4645 79.8284C13.2692 80.0237 13.2692 80.3403 13.4645 80.5355C13.6597 80.7308 13.9763 80.7308 14.1716 80.5355L17.3536 77.3536ZM0.5 17V61H1.5V17H0.5ZM0.5 61C0.5 70.1127 7.8873 77.5 17 77.5V76.5C8.43959 76.5 1.5 69.5604 1.5 61H0.5ZM17 0.5C7.8873 0.5 0.5 7.8873 0.5 17H1.5C1.5 8.43959 8.43959 1.5 17 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="18" height="146" viewBox="0 0 18 146" fill="none" xmlns="http://www.w3.org/2000/svg" key="lineml2">
    <path
      d="M17.3536 142.354C17.5488 142.158 17.5488 141.842 17.3536 141.646L14.1716 138.464C13.9763 138.269 13.6597 138.269 13.4645 138.464C13.2692 138.66 13.2692 138.976 13.4645 139.172L16.2929 142L13.4645 144.828C13.2692 145.024 13.2692 145.34 13.4645 145.536C13.6597 145.731 13.9763 145.731 14.1716 145.536L17.3536 142.354ZM0.5 17V126H1.5V17H0.5ZM0.5 126C0.5 135.113 7.8873 142.5 17 142.5V141.5C8.43959 141.5 1.5 134.56 1.5 126H0.5ZM17 0.5C7.8873 0.5 0.5 7.8873 0.5 17H1.5C1.5 8.43959 8.43959 1.5 17 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="18" height="211" viewBox="0 0 18 211" fill="none" xmlns="http://www.w3.org/2000/svg" key="lineml3">
    <path
      d="M17.3536 207.354C17.5488 207.158 17.5488 206.842 17.3536 206.646L14.1716 203.464C13.9763 203.269 13.6597 203.269 13.4645 203.464C13.2692 203.66 13.2692 203.976 13.4645 204.172L16.2929 207L13.4645 209.828C13.2692 210.024 13.2692 210.34 13.4645 210.536C13.6597 210.731 13.9763 210.731 14.1716 210.536L17.3536 207.354ZM0.5 17V191H1.5V17H0.5ZM0.5 191C0.5 200.113 7.8873 207.5 17 207.5V206.5C8.43959 206.5 1.5 199.56 1.5 191H0.5ZM17 0.5C7.8873 0.5 0.5 7.8873 0.5 17H1.5C1.5 8.43959 8.43959 1.5 17 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="18" height="276" viewBox="0 0 18 276" fill="none" xmlns="http://www.w3.org/2000/svg" key="lineml4">
    <path
      d="M17.3536 272.354C17.5488 272.158 17.5488 271.842 17.3536 271.646L14.1716 268.464C13.9763 268.269 13.6597 268.269 13.4645 268.464C13.2692 268.66 13.2692 268.976 13.4645 269.172L16.2929 272L13.4645 274.828C13.2692 275.024 13.2692 275.34 13.4645 275.536C13.6597 275.731 13.9763 275.731 14.1716 275.536L17.3536 272.354ZM0.5 17V256H1.5V17H0.5ZM0.5 256C0.5 265.113 7.8873 272.5 17 272.5V271.5C8.43959 271.5 1.5 264.56 1.5 256H0.5ZM17 0.5C7.8873 0.5 0.5 7.8873 0.5 17H1.5C1.5 8.43959 8.43959 1.5 17 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="18" height="338" viewBox="0 0 18 338" fill="none" xmlns="http://www.w3.org/2000/svg" key="lineml5">
    <path
      d="M17.8536 334.354C18.0488 334.158 18.0488 333.842 17.8536 333.646L14.6716 330.464C14.4763 330.269 14.1597 330.269 13.9645 330.464C13.7692 330.66 13.7692 330.976 13.9645 331.172L16.7929 334L13.9645 336.828C13.7692 337.024 13.7692 337.34 13.9645 337.536C14.1597 337.731 14.4763 337.731 14.6716 337.536L17.8536 334.354ZM0 18V317H1V18H0ZM0 317C0 326.665 7.83502 334.5 17.5 334.5V333.5C8.3873 333.5 1 326.113 1 317H0ZM17.5 0.5C7.83502 0.5 0 8.33502 0 18H1C1 8.8873 8.3873 1.5 17.5 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
]

export const mobileRightLines = [
  <svg width="18" height="81" viewBox="0 0 18 81" fill="none" xmlns="http://www.w3.org/2000/svg" key="linemr1">
    <path
      d="M0.646446 77.3536C0.451185 77.1583 0.451185 76.8417 0.646446 76.6464L3.82843 73.4645C4.02369 73.2692 4.34027 73.2692 4.53553 73.4645C4.7308 73.6597 4.7308 73.9763 4.53553 74.1716L1.70711 77L4.53553 79.8284C4.7308 80.0237 4.7308 80.3403 4.53553 80.5355C4.34027 80.7308 4.02369 80.7308 3.82843 80.5355L0.646446 77.3536ZM17.5 17V61H16.5V17H17.5ZM17.5 61C17.5 70.1127 10.1127 77.5 1 77.5V76.5C9.56041 76.5 16.5 69.5604 16.5 61H17.5ZM1 0.5C10.1127 0.5 17.5 7.8873 17.5 17H16.5C16.5 8.43959 9.56041 1.5 1 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="18" height="146" viewBox="0 0 18 146" fill="none" xmlns="http://www.w3.org/2000/svg" key="linemr2">
    <path
      d="M0.646446 142.354C0.451185 142.158 0.451185 141.842 0.646446 141.646L3.82843 138.464C4.02369 138.269 4.34027 138.269 4.53553 138.464C4.7308 138.66 4.7308 138.976 4.53553 139.172L1.70711 142L4.53553 144.828C4.7308 145.024 4.7308 145.34 4.53553 145.536C4.34027 145.731 4.02369 145.731 3.82843 145.536L0.646446 142.354ZM17.5 17V126H16.5V17H17.5ZM17.5 126C17.5 135.113 10.1127 142.5 1 142.5V141.5C9.56041 141.5 16.5 134.56 16.5 126H17.5ZM1 0.5C10.1127 0.5 17.5 7.8873 17.5 17H16.5C16.5 8.43959 9.56041 1.5 1 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="18" height="211" viewBox="0 0 18 211" fill="none" xmlns="http://www.w3.org/2000/svg" key="linemr3">
    <path
      d="M0.646446 207.354C0.451185 207.158 0.451185 206.842 0.646446 206.646L3.82843 203.464C4.02369 203.269 4.34027 203.269 4.53553 203.464C4.7308 203.66 4.7308 203.976 4.53553 204.172L1.70711 207L4.53553 209.828C4.7308 210.024 4.7308 210.34 4.53553 210.536C4.34027 210.731 4.02369 210.731 3.82843 210.536L0.646446 207.354ZM17.5 17V191H16.5V17H17.5ZM17.5 191C17.5 200.113 10.1127 207.5 1 207.5V206.5C9.56041 206.5 16.5 199.56 16.5 191H17.5ZM1 0.5C10.1127 0.5 17.5 7.8873 17.5 17H16.5C16.5 8.43959 9.56041 1.5 1 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="18" height="276" viewBox="0 0 18 276" fill="none" xmlns="http://www.w3.org/2000/svg" key="linemr4">
    <path
      d="M0.646446 272.354C0.451185 272.158 0.451185 271.842 0.646446 271.646L3.82843 268.464C4.02369 268.269 4.34027 268.269 4.53553 268.464C4.7308 268.66 4.7308 268.976 4.53553 269.172L1.70711 272L4.53553 274.828C4.7308 275.024 4.7308 275.34 4.53553 275.536C4.34027 275.731 4.02369 275.731 3.82843 275.536L0.646446 272.354ZM17.5 17V256H16.5V17H17.5ZM17.5 256C17.5 265.113 10.1127 272.5 1 272.5V271.5C9.56041 271.5 16.5 264.56 16.5 256H17.5ZM1 0.5C10.1127 0.5 17.5 7.8873 17.5 17H16.5C16.5 8.43959 9.56041 1.5 1 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
  <svg width="19" height="338" viewBox="0 0 19 338" fill="none" xmlns="http://www.w3.org/2000/svg" key="linemr5">
    <path
      d="M0.646446 334.354C0.451185 334.158 0.451185 333.842 0.646446 333.646L3.82843 330.464C4.02369 330.269 4.34027 330.269 4.53553 330.464C4.7308 330.66 4.7308 330.976 4.53553 331.172L1.70711 334L4.53553 336.828C4.7308 337.024 4.7308 337.34 4.53553 337.536C4.34027 337.731 4.02369 337.731 3.82843 337.536L0.646446 334.354ZM18.5 18V317H17.5V18H18.5ZM18.5 317C18.5 326.665 10.665 334.5 1 334.5V333.5C10.1127 333.5 17.5 326.113 17.5 317H18.5ZM1 0.5C10.665 0.5 18.5 8.33502 18.5 18H17.5C17.5 8.8873 10.1127 1.5 1 1.5V0.5Z"
      fill="#3157DC"
    />
  </svg>,
]

export const twoWayConnectorIcon = (
  <svg width="9" height="26" viewBox="0 0 9 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.76597 0.646447C4.57071 0.451184 4.25412 0.451184 4.05886 0.646447L0.876881 3.82843C0.681618 4.02369 0.681618 4.34027 0.876881 4.53553C1.07214 4.7308 1.38873 4.7308 1.58399 4.53553L4.41241 1.70711L7.24084 4.53553C7.4361 4.7308 7.75269 4.7308 7.94795 4.53553C8.14321 4.34027 8.14321 4.02369 7.94795 3.82843L4.76597 0.646447ZM4.05886 25.3536C4.25412 25.5488 4.57071 25.5488 4.76597 25.3536L7.94795 22.1716C8.14321 21.9763 8.14321 21.6597 7.94795 21.4645C7.75269 21.2692 7.4361 21.2692 7.24084 21.4645L4.41241 24.2929L1.58399 21.4645C1.38873 21.2692 1.07214 21.2692 0.876881 21.4645C0.681618 21.6597 0.681618 21.9763 0.876881 22.1716L4.05886 25.3536ZM3.91241 1V13H4.91241V1H3.91241ZM3.91241 13V25H4.91241V13H3.91241Z"
      fill="#3157DC"
    />
  </svg>
)

export const topBottomConnector = (
  <svg width="9" height="28" viewBox="0 0 9 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.74575 3C1.74575 4.47276 2.93966 5.66667 4.41241 5.66667C5.88517 5.66667 7.07908 4.47276 7.07908 3C7.07908 1.52724 5.88517 0.333333 4.41241 0.333333C2.93966 0.333333 1.74575 1.52724 1.74575 3ZM4.05886 27.3536C4.25412 27.5488 4.57071 27.5488 4.76597 27.3536L7.94795 24.1716C8.14321 23.9763 8.14321 23.6597 7.94795 23.4645C7.75269 23.2692 7.4361 23.2692 7.24084 23.4645L4.41241 26.2929L1.58399 23.4645C1.38873 23.2692 1.07214 23.2692 0.876881 23.4645C0.681618 23.6597 0.681618 23.9763 0.876881 24.1716L4.05886 27.3536ZM3.91241 3V15H4.91241V3H3.91241ZM3.91241 15V27H4.91241V15H3.91241Z"
      fill="#3157DC"
    />
  </svg>
)

export const topMuliLineLeft = (
  <svg width="223" height="42" viewBox="0 0 223 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.14645 41.3536C4.34171 41.5488 4.65829 41.5488 4.85355 41.3536L8.03553 38.1716C8.2308 37.9763 8.2308 37.6597 8.03553 37.4645C7.84027 37.2692 7.52369 37.2692 7.32843 37.4645L4.5 40.2929L1.67157 37.4645C1.47631 37.2692 1.15973 37.2692 0.964466 37.4645C0.769204 37.6597 0.769204 37.9763 0.964466 38.1716L4.14645 41.3536ZM221.5 0V0.5H222.5V0H221.5ZM202 20H24.5V21H202V20ZM4 40.5V41H5V40.5H4ZM24.5 20C13.1782 20 4 29.1782 4 40.5H5C5 29.7304 13.7305 21 24.5 21V20ZM221.5 0.5C221.5 11.2696 212.77 20 202 20V21C213.322 21 222.5 11.8218 222.5 0.5H221.5Z"
      fill="#3157DC"
    />
  </svg>
)

export const topMultiLineRight = (
  <svg width="222" height="42" viewBox="0 0 222 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M217.646 41.3536C217.842 41.5488 218.158 41.5488 218.354 41.3536L221.536 38.1716C221.731 37.9763 221.731 37.6597 221.536 37.4645C221.34 37.2692 221.024 37.2692 220.828 37.4645L218 40.2929L215.172 37.4645C214.976 37.2692 214.66 37.2692 214.464 37.4645C214.269 37.6597 214.269 37.9763 214.464 38.1716L217.646 41.3536ZM0.5 0V0.5H1.5V0H0.5ZM21 21H198V20H21V21ZM217.5 40.5V41H218.5V40.5H217.5ZM198 21C208.77 21 217.5 29.7304 217.5 40.5H218.5C218.5 29.1782 209.322 20 198 20V21ZM0.5 0.5C0.5 11.8218 9.67816 21 21 21V20C10.2304 20 1.5 11.2696 1.5 0.5H0.5Z"
      fill="#3157DC"
    />
  </svg>
)
