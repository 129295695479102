import {useRef, useState, useEffect} from 'react'
import {useScroll, motion} from 'framer-motion'

import ZenCore from 'v1/page-components/home-page/product-module/ZenCore'
import ZenHR from 'v1/page-components/home-page/product-module/ZenHR'
import ZenIT from 'v1/page-components/home-page/product-module/ZenIT'
import ZenEX from 'v1/page-components/home-page/product-module/ZenEX'
import ZenPayroll from './zen-payroll/ZenPayroll'
import classes from './styles.module.css'

const products = [
  {id: 'ZenCore', component: <ZenCore />},
  {id: 'ZenHR', component: <ZenHR />},
  {id: 'ZenIT', component: <ZenIT />},
  {id: 'ZenEX', component: <ZenEX />},
  {id: 'ZenPayroll', component: <ZenPayroll />},
]

export default function ProductModule() {
  const container = useRef(null)

  const [prevScrollY, setPrevScrollY] = useState(0)

  const {scrollY} = useScroll()

  useEffect(() => {
    return scrollY.on('change', latest => {
      setPrevScrollY(latest)
    })
  }, [scrollY, prevScrollY])

  return (
    <div ref={container}>
      {products?.map((product) => (
        <motion.div
          key={product.id}
          className={classes.moduleContainer}
          initial="visible"
          transition={{duration: 0.5}}
        >
          {product.component}
        </motion.div>
      ))}
    </div>
  )
}
