import Image from 'next/image'
import clsx from 'clsx'
import {motion} from 'framer-motion'
import Link from 'next/link'

import {allInOneSolutionData} from 'v1/content/home-page'
import {
  lines,
  connectorIcon,
  singleCardBottomLines,
  singleCardTopLines,
  mobileLeftLines,
  mobileRightLines,
  twoWayConnectorIcon,
  topBottomConnector,
  topMuliLineLeft,
  topMultiLineRight,
} from './svgLines'

import ArrowRight from 'v1/assets/icons/arrow-right.svg'
import ChevronRight from 'v1/assets/icons/chevron-right.svg'
import user1 from 'v1/assets/icons/user-chip-icons/user-1.svg'
import user2 from 'v1/assets/icons/user-chip-icons/user-2.svg'
import user3 from 'v1/assets/icons/user-chip-icons/user-3.svg'
import classes from './styles.module.css'

export default function AllInOneSolution() {
  return (
    <section className={classes.allInOneContainer}>
      <h2 className={clsx('h2-v1 text-center', classes.heading)}>
        {allInOneSolutionData?.title1} {allInOneSolutionData?.title2}
      </h2>
      <div className={classes.multiCardContainer}>
        {allInOneSolutionData?.multiProductsCardList?.map((productDetails, idx) => (
          <div key={productDetails?.key} className={classes.multiCard}>
            {idx !== 0 && <div className={classes.twoWayConnector}>{twoWayConnectorIcon}</div>}
            <MultiProductCard details={productDetails} />
            {lines[idx] && (
              <div
                className={clsx(
                  idx === 0 ? classes.svgLine0 : idx === 1 ? classes.svgLine1 : classes.svgLine2,
                )}
              >
                {lines[idx]}
              </div>
            )}
            {idx !== 0 && <span className={classes.svgConnector}>{connectorIcon}</span>}
          </div>
        ))}
      </div>
      <div className={classes.mobileSingleCardContainer}>
        <div className={classes.mobileTopToBottomArrow}>{topBottomConnector}</div>
        <SingleProductCard details={allInOneSolutionData.singleProductCardData} />
      </div>
    </section>
  )
}

function MultiProductCard({details}: {details: any}) {
  const isZenIt = details?.key === 'zenit'
  const listArrowVariants = {
    hidden: {x: 0},
    hover: {x: 8},
  }

  const getList = (list: any, idx?: number) => (
    <li
      key={idx}
      className={clsx(
        classes.productList,
        classes[`${details?.key}Border`],
        isZenIt && classes.productListHover,
      )}
    >
      <div className={classes.productListText}>
        <span className={classes.listCompanyIcon}>
          <Image src={list?.icon} width={16} alt="company-logo" />
        </span>
        <span className="h8-v1">{list?.text}</span>
      </div>
      {isZenIt ? (
        <motion.div
          className={classes.listArrow}
          variants={listArrowVariants}
          initial="hidden"
          whileHover="hover"
          transition={{type: 'tween', duration: 0.3}}
        >
          <Image src={ArrowRight} width={16} alt="arrow" />
        </motion.div>
      ) : null}{' '}
    </li>
  )

  return (
    <div className={clsx(classes.multiProductCard, classes[`${details?.key}Border`])}>
      <div
        className={clsx(
          classes.multiCardHeader,
          classes[`${details?.key}Border`],
          classes[`${details?.key}BgColor`],
        )}
      >
        <Image src={details?.icon} width={40} alt="company-logo" />
        <div className={classes.headerDetails}>
          <h4 className="h4-v1">{details?.title}</h4>
          <p className={clsx('paragraph3-v1', classes.textCenter)}>{details?.subTitle}</p>
        </div>
      </div>
      <ul>
        {details?.list?.map((list: any, index: number) =>
          list?.link ? (
            <Link href={list?.link} key={index} style={{cursor: list?.link ? 'pointer' : 'auto'}}>
              {getList(list, index)}
            </Link>
          ) : (
            getList(list, index)
          ),
        )}
      </ul>
      <div className={classes.footerDetails}>
        <h5 className="h7-v1">{details?.footerData?.title}</h5>
        {details?.footerData?.content ? (
          <p className={classes.footerContent}>{details?.footerData?.content}</p>
        ) : null}
        {isZenIt ? (
          <Link href={details?.footerData?.knowMoreLink} className={classes.knowMoreBtn}>
            <span>Know more</span>
            <Image src={ChevronRight} width={12} alt="arrow-icon" />
          </Link>
        ) : null}
      </div>
    </div>
  )
}

function SingleProductCard({details}: {details: any}) {
  return (
    <div className={classes.singleProductCard}>
      <div className={classes.singleCardHeaderContainer}>
        <div className={clsx(classes.multiCardHeader, classes.singleCardHeader)}>
          <Image src={details?.icon} width={40} alt="company-logo" />
          <div className={classes.headerDetails}>
            <h4 className="h4-v1">{details?.title}</h4>
            <p className="paragraph3-v1">{details?.subTitle}</p>
          </div>
        </div>
        <div className={classes.multiLinesContainer}>
          {' '}
          {singleCardTopLines?.map((line, idx) => (
            <div
              key={idx}
              className={clsx(
                idx === 0 ? classes.svgLine20 : idx === 1 ? classes.svgLine22 : classes.svgLine24,
              )}
            >
              {line}
            </div>
          ))}
          <div className={classes.svgLine21}>{topMuliLineLeft}</div>
          <div className={classes.svgLine23}>{topMultiLineRight}</div>
        </div>
      </div>
      <div className={classes.signleCardWithLine}>
        <div className={classes.mobileLeftLinesContainer}>
          {mobileLeftLines?.map((line, idx) => (
            <div key={idx} className={classes.mobileLeftLine}>
              {line}
            </div>
          ))}
        </div>
        <div className={classes.singleCardListContainer}>
          {details?.list?.map((list: any, index: number) => (
            <div key={index} className={classes.singleCardList}>
              <span className={classes.listCompanyIcon}>
                <Image src={list?.icon} width={16} alt="company-logo" />
              </span>
              <span className="h8-v1">{list?.text}</span>
            </div>
          ))}
        </div>
        <div className={classes.mobileLeftLinesContainer}>
          {mobileRightLines?.map((line, idx) => (
            <div key={idx} className={classes.mobileRightLine}>
              {line}
            </div>
          ))}
        </div>
      </div>
      <div className={classes.multiUserChipContainer}>
        <div className={classes.svgLine}>{singleCardBottomLines?.[0]}</div>
        <div className={classes.svgLine31}>{singleCardBottomLines?.[1]}</div>
        <div className={classes.svgLine32}>{singleCardBottomLines?.[2]}</div>
        <button className={classes.userChipInnerContainer}>
          <div className={classes.multiUserChipCard}>
            <Image src={user1} width={20} alt="user-icon" />
            <Image className={classes.UserChip} src={user2} width={20} alt="user-icon" />
            <Image className={classes.UserChip} src={user3} width={20} alt="user-icon" />
          </div>
          <span className="h8-v1">{details?.footerData?.title}</span>
        </button>
        <div className={classes.svgLine33}>{singleCardBottomLines?.[3]}</div>
        <div className={classes.svgLine}>{singleCardBottomLines?.[4]}</div>
      </div>
    </div>
  )
}
