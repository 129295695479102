'use client'
import * as React from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import {motion} from 'framer-motion'
import {Swiper, SwiperSlide, useSwiper} from 'swiper/react'
import {EffectFade} from 'swiper/modules'

import {IManageHybr1dData} from 'v1/types/home-page'

import ExpandMore from 'v1/assets/icons/expand-more.svg'
import ExpandLess from 'v1/assets/icons/expand-less.svg'
import classes from './styles.module.css'

import 'swiper/css'
import 'swiper/css/effect-fade'

interface VerticalCarouselProps {
  data: IManageHybr1dData
  activeTitleTextColorClass?: string
  imageBgColorClass?: string
  activeBgColor?: string
}

export default function VerticalCarousel({
  data,
  activeTitleTextColorClass,
  imageBgColorClass,
  activeBgColor,
}: VerticalCarouselProps) {
  const [activeSlide, setActiveSlide] = React.useState<number>(0)

  const handleIndexChange = () => {
    if (activeSlide === data.slides.length - 1) {
      setActiveSlide(0)
    } else {
      setActiveSlide(prev => prev + 1)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.slidesContainer}>
        <div className={classes.progressBarParentContainer}>
          <div>
            <h6 className={clsx(classes.topTitle, activeTitleTextColorClass)}>{data?.topTitle}</h6>
            <h2 className={clsx('h2-v1',classes.header)}>
              <div className={classes.titleLogo}>
                <Image src={data?.titleLogo} alt="small-logo" height={40} width={40} />
              </div>
              {data.title}
            </h2>
            <p className={classes.desc}> {data.desc}</p>
          </div>
          <div className={classes.progressListWrapper}>
            {data.slides.map((progress, index) => (
              <div
                key={progress.btnDetails.title}
                className={clsx(
                  classes.singleProgressContainer,
                  index === activeSlide ? classes.singleProgressContainerActive : '',
                )}
                onClick={() => {
                  setActiveSlide(index)
                }}
              >
                {index === activeSlide ? (
                  <div className={classes.progressDetailsContainer}>
                    <LoaderBar
                      imageBgColorClass={activeBgColor}
                      handleIndexChange={handleIndexChange}
                    />
                    <div className={classes.mobileSlideContainer}>
                      <div className={classes.activeProgressDetails}>
                        <div
                          className={clsx(
                            classes.progressTitleText,
                            classes.topTitle,
                            classes.activeProgressTitleText,
                            activeTitleTextColorClass,
                          )}
                        >
                          {progress.btnDetails.title}
                          <Image src={ExpandLess} alt="expand-less" />
                        </div>
                        <div className={classes.mobileSlideImage}>
                          <Image
                            className={clsx(classes.slideImage, imageBgColorClass)}
                            src={progress.slideDetails.img.src}
                            alt="ZenAdmin works"
                          />
                        </div>
                        <div className={classes.progressBoldDesc}>
                          {' '}
                          {progress.btnDetails.boldDesc}
                        </div>
                        <div className={classes.progressDesc}> {progress.btnDetails.desc}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={classes.progressDetailsContainer}>
                    <div className={classes.inActiveLoaderBar}></div>
                    <div className={clsx(classes.progressTitleText, classes.topTitle)}>
                      {progress.btnDetails.title}
                      <Image src={ExpandMore} alt="expand-more" />
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={classes.imageParentContainer}>
          <Swiper
            noSwiping={true}
            allowTouchMove={false}
            loop={true}
            effect={'fade'}
            pagination={{
              clickable: false,
            }}
            modules={[EffectFade]}
            autoHeight={true}
          >
            {data.slides?.map((card, idx) => (
              <SwiperSlide key={idx}>
                <Image
                  className={clsx(classes.slideImage, imageBgColorClass)}
                  src={card.slideDetails.img.src}
                  alt="ZenAdmin works"
                  height={card.slideDetails.img.height}
                  width={card.slideDetails.img.width}
                />
              </SwiperSlide>
            ))}
            <HiddenSwiperController activeSlide={activeSlide} />
          </Swiper>
        </div>
      </div>
    </div>
  )
}

const HiddenSwiperController = ({activeSlide}: {activeSlide: number}) => {
  const swiper = useSwiper()

  React.useEffect(() => {
    if (swiper) {
      swiper.slideTo(activeSlide, 0) // Change slide with no transition
    }
  }, [activeSlide, swiper])

  return <></>
}

const LoaderBar = ({
  handleIndexChange,
  imageBgColorClass,
}: {
  handleIndexChange: () => void
  imageBgColorClass?: string
}) => {
  return (
    <div className={classes.progressBarContainer}>
      <div className={classes.progressBar} />
      <motion.div
        className={clsx(classes.progressBarPseudo, imageBgColorClass)}
        animate={{
          height: ['0%', '100%'],
        }}
        transition={{
          duration: 0,
          ease: 'easeIn',
        }}
      ></motion.div>
    </div>
  )
}
